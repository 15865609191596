@import '@zcode-ui/zumper-theme/styles/global/color.scss';
@import '@zcode-ui/zumper-theme/styles/global/z_index.scss';

.container {
  position: relative;

  width: 100%;
}

.tooltipContainer {
  position: absolute;
  top: 15px;

  width: 100%;
}

.emailTooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: $zIndexTooltip;

  margin: 0 auto;
  padding: 10px 20px;

  color: $white;
  line-height: 1.5em;
  text-align: center;

  background-color: $slateDarker;
  border-radius: 3px;
  transform: translateX(-50%);
  opacity: 0.9;

  &::after {
    position: absolute;
    top: 100%;
    left: 50%;

    width: 0;
    height: 0;
    margin-left: -5px;

    border: 5px solid transparent;
    border-top-color: $slateDarker;

    content: ' ';
    pointer-events: none;
  }
}

.suggestion {
  color: $white;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.emailTooltipX {
  position: absolute;
  top: 5px;
  right: 0;

  margin: 0 5px;

  color: $white;

  &:hover {
    color: $white;
  }
}
