@critical true;

.favoriteBtnContent {
  position: absolute;
  top: 50%;
  right: 50%;

  display: flex;
  align-items: center;

  transform: translate(50%, -50%);

  path {
    stroke-width: 2;
  }
}

.favoriteBtnText {
  margin-left: 8px;
}

.favoriteIconStyles {
  position: relative;

  width: 32px;
  height: 32px;

  background: var(--chakra-colors-gray-0);
  border-color: var(--chakra-colors-gray-150);
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;

  @media (min-width: 480px) {
    border-width: 0;
    border-radius: 0;
  }
}

.roundBtnStyle {
  width: 32px;
  height: 32px;

  border-radius: 50%;
}

.transparent {
  background: transparent;
  border: 0;
}
