.syntheticButton {
  display: inline-block;
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;

  cursor: pointer;

  user-select: none;
}

.syntheticButton:disabled {
  cursor: default;
  opacity: 0.45;
}
