@import '@zcode-ui/zumper-theme/styles/global/color.scss';
@import '@zcode-ui/zumper-theme/styles/global/mixins.scss';
@import '@zcode-ui/zumper-theme/styles/global/typography.scss';

.FarAwayMoveIn {
  margin-bottom: 10px;
  padding: 14px 12px;

  background-color: $gray-05;

  border-radius: 5px;
}

.headerline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.title {
  font-weight: $bold;
  font-size: 16px;
  line-height: 140%;
}

.text {
  margin-top: 4px;
  margin-bottom: 0;

  font-size: 14px;
  line-height: 150%;

  white-space: pre-wrap;

  @include is-mobile {
    margin-bottom: 22px;
  }
}

.phoneBtn {
  width: 100%;
  padding: 6px 12px;

  color: $blue;
  font-weight: bold;
  font-size: 15px;
  line-height: 26px;

  border: 0.75px solid $blue;
  border-radius: 4px;
}

.callNowIcon {
  padding-right: 6px;

  transform: translateY(3px);

  @media (max-width: 320px) {
    padding-right: 0;
  }
}

.callText {
  @media (max-width: 320px) {
    display: none;
  }

  @media (min-width: 321px) {
    display: inline;
  }
}
