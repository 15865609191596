@import '@zcode-ui/zumper-theme/styles/global/color.scss';
@import '@zcode-ui/zumper-theme/styles/global/grid.scss';
@import '@zcode-ui/zumper-theme/styles/global/typography.scss';

.promptContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 12px;

  border: 1px solid $gray-30;
  border-radius: 5px;
}

.promptHeader,
.promptSubHeader {
  font-size: 14px;
  line-height: 21px;

  @media (max-width: $breakpoint-374) {
    font-size: 13px;
  }
}

.promptHeader {
  font-weight: $bold;
}

.promptSubHeader {
  color: $gray-90;
}
