@import '@zcode-ui/zumper-theme/styles/global/color.scss';
@import '@zcode-ui/zumper-theme/styles/global/typography.scss';

.topContainer {
  padding: 20px;
}

.topRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.price {
  font-size: 28px;
  line-height: 32px;
}

.noPrice {
  color: $gray-70;
}

.bottomContainer {
  padding: 20px;

  border-top: 1px solid #e3e6e9;
}

.header {
  display: inline-block;
  padding-bottom: 16px;

  color: $gray-70;
  font-weight: $bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.phoneContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.callIcon {
  margin-right: 8px;
}

.phone {
  padding-left: 6px;
}
